<template>
  <q-input :label="label" :color="color" class="q-pa-none fit"
    :value="$formatDate(value)" @blur="validate" @keydown.enter.stop="validate" :ref="`${label}_date_input`"
    :rules="[isValidDate]">
    <template v-slot:append>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy ref="qDateProxy">
          <q-date :color="color" today-btn
            :value="$formatDate(value, 'YYYY/MM/DD')" :title="$formatDate(value, 'dddd DD MMM')"
            @input="val => { $emit('input', $formatDate(new Date(val), 'YYYY-MM-DD')); $refs['qDateProxy'].hide() }" />
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
export default {
  name: 'FilterDatePicker',
  props: ['model', 'id', 'label', 'color', 'value'],
  methods: {
    isValidDate (v) {
      if (!v || v === '') return true
      return /^-?[0-3]\d\/[0-1]\d\/[\d]+$/.test(v)
    },

    validate (event) {
      const value = event.target.value

      if (this.isValidDate(value)) {
        if (!value || '') {
          this.$emit('input', null)
        } else {
          const [day, month, year] = value.split('/')
          const newDate = new Date(year, month - 1, day)
          this.$emit('input', this.$formatDate(newDate, 'YYYY-MM-DD'))
        }
      } else {
        this.$emit('input', null)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
