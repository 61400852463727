<template>
  <q-input :label="label" :color="color" class="q-pa-none fit"
    :value="value" @input ="onInput">
  </q-input>
</template>

<script>
export default {
  name: 'FilterText',
  props: ['model', 'id', 'label', 'color', 'value'],
  methods: {
    onInput (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang='stylus' scoped>
</style>
