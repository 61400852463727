<template>
  <q-select :value="value" @input="val => $emit('input', val)"
    :label="label" :color="color" class="fit"
    :options="computedOptions" emit-value map-options
    :multiple="!!computedMultiple"
    options-dense use-chips>

    <template v-slot:selected-item="scope">
      <q-chip @remove="$emit('input', multiple ? value.filter(val => val !== scope.opt.value): null) "
        dense removable :color="color" text-color="white" size="sm">
        <q-icon v-if="id === 'qualification'" :name="$getIcon(id, Number(scope.opt.value))" />
        <div v-else>{{scope.opt.label}}</div>
      </q-chip>
    </template>

    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section>
          <q-icon v-if="id === 'qualification'" :name="$getIcon(id, Number(scope.opt.value))" />
          <div v-else>{{scope.opt.label}}</div>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
export default {
  name: 'FilterSelect',
  props: {
    'id': { type: String, required: true },
    'label': { type: String },
    'color': { type: String },
    'options': { type: Array },
    'value': { },
    'multiple': { type: Boolean, default: true },
    'boolean': { type: Boolean, default: false }
  },
  computed: {
    computedOptions () {
      return this.boolean
        ? [
          { label: 'Oui', value: '1' },
          { label: 'Non', value: '0' }
        ]
        : this.options || []
    },
    computedMultiple () {
      return this.boolean ? false : this.multiple
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
