<template>
  <search-box :label="label" :model_type="modelType" :value="value" @input="val => $emit('input', val.value ? val.value.id : null)" v-bind="$props" />
</template>

<script>
export default {
  name: 'FilterSearchBox',
  props: ['model', 'id', 'label', 'color', 'value', 'model_type', 'label_field'],
  computed: {
    modelType () {
      let getModeTypeByLabel = (label) => {
        switch (label.toLowerCase()) {
          case 'portefeuille': return 'wallet'
          case 'agent': return 'agent'
          default: return label.toLowerCase()
        }
      }
      return this.model_type ? this.model_type : getModeTypeByLabel(this.label)
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
