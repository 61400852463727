<template>
  <q-list class="full-width" :class="{ 'q-pl-md': !isFromFilterGroup }" dense>
    <div v-if="!isFromFilterGroup" class="row justify-between">
      <q-item-label header>Filtres</q-item-label>
      <div class="q-pa-sm" v-if="haveFilterActive">
        <q-btn :color="color" icon="close" size="sm" @click="resetFilter" />
      </div>
    </div>
    <q-item v-for="(filter, index) in filters" v-bind:key="filter.label" class="column">
      <component :is="getComponent(filter.type)" v-model="filters[index].value" v-bind="filter"
        :label="$t(`${model}.filters.${filter.id}.label`)" :color="color"
        :model="model"
        :min="getRange(filter, 'min')"
        :max="getRange(filter, 'max')"
        :options="filter.options && trOptions(filter)"
        :boolean="filter.type === 'boolean'"
      />
    </q-item>
  </q-list>
</template>

<script>
import { FilterText, FilterSelect, FilterDatePicker, FilterSearchBox, FilterGroup, FilterRange } from './'

export default {
  name: 'Filters',
  props: ['model', 'color', 'value'],

  computed: {
    isFromFilterGroup () {
      return !!this.value
    },
    haveFilterActive () {
      const isfilterActive = (filter) => {
        if (filter.type === 'group') {
          for (let item of filter.items) {
            if (isfilterActive(item)) return true
          }
          return false
        } else {
          if (Array.isArray(filter.value)) return filter.value.length !== 0
          else return filter.value
        }
      }

      if (!this.filters) return false
      return this.filters.find(isfilterActive)
    },
    storeFilters: {
      get () {
        return this.$store.getters[`${this.model}/getFilters`]
      },
      set () { this.$store.commit(`${this.model}/setFilters`) }
    },
    filters: {
      get () {
        return this.value || this.storeFilters
      },
      set (newVal) {
        this.storeFilters = newVal
      }
    }
  },

  methods: {
    trOptions (filter) {
      return Array.isArray(filter.options) ? filter.options.map(option => {
        let key = `${this.model}.filters.${filter.id}.options.${option.label}`
        return this.$te(key) ? {
          ...option, label: this.$t(key)
        } : option
      }) : filter.options
    },

    getRange (filter, type) {
      return (filter.type === 'range' && filter.options) ? filter.options[type] : 0
    },

    getComponent (type) {
      if (type === 'select') {
        return FilterSelect
      } else if (type === 'date') {
        return FilterDatePicker
      } else if (type === 'search') {
        return FilterSearchBox
      } else if (type === 'text') {
        return FilterText
      } else if (type === 'group') {
        return FilterGroup
      } else if (type === 'range') {
        return FilterRange
      } else if (type === 'boolean') {
        return FilterSelect
      }
      return null
    },

    resetFilter () {
      const resetFilter = (filter) => {
        if (filter.type === 'group') {
          for (let item of filter.items) resetFilter(item)
        } else if (filter.value) {
          filter.value = null
        }
      }
      this.filters.forEach(resetFilter)
    }
  }
}
</script>

<style>
</style>
