<template>
  <q-select :value="value"
    :label="label" :color="color" class="fit"
    :options="[{ }]" emit-value map-options
    options-dense use-chips>

    <template v-slot:selected-item>
      <q-chip @remove="$emit('input', onInput({ min: minFloor, max: maxCeil }))"
        dense removable :color="color" text-color="white" size="sm">
        {{ range.min }}-{{ range.max }}
      </q-chip>
    </template>

    <template v-slot:option>
      <div class="q-px-xl q-pt-xl q-pb-lg">
        <q-range
          dense
          :value="range"
          @change="onInput"
          :min="minFloor"
          :max="maxCeil"
          :color="color"
          label-always
        />
      </div>
    </template>
  </q-select>
</template>

<script>
export default {
  name: 'FilterRange',
  computed: {
    range: {
      get: function () {
        if (this.value) {
          const splitedValue = this.value.split('-')
          if (splitedValue.length === 2) return { min: splitedValue[0], max: splitedValue[1] }
        }

        return { min: this.minFloor, max: this.maxCeil }
      }
    },
    minFloor () {
      return Math.floor(this.min)
    },
    maxCeil () {
      return Math.ceil(this.max)
    }
  },
  props: ['label', 'color', 'value', 'min', 'max'],
  methods: {
    onInput (val) {
      const min = Math.floor(Math.min(val.min, val.max))
      const max = Math.ceil(Math.max(val.min, val.max))

      this.$emit('input', `${min}-${max}`)
    }
  }
}
</script>

<style lang='stylus' scoped>
</style>
