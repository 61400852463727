<template>
  <q-expansion-item
    expand-separator
    :label="label"
    class="full-width q-px-none text-subtitle1"
    header-class="text-uppercase q-px-none"
  >
    <filters v-model="items" :color="color" :model="model" />
  </q-expansion-item>
</template>

<script>
import Filters from './Filters.vue'
export default {
  name: 'FilterGroup',
  props: ['model', 'label', 'items', 'color'],
  components: { Filters }
}
</script>

<style lang='stylus' scoped>
</style>
